import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingOroPrestige from "./landing/landingOroPrestige/landingOroPrestige";
import "./index.css";


function App() {

    

  return (
    <div className="App">
        <Router>
            <Routes>
                <Route  path="/*" element={<Navigate to="/oro-prestige-lingotti-in-gioielleria" replace/>} />
                <Route exact path="/oro-prestige-lingotti-in-gioielleria" element={<LandingOroPrestige />} />
            </Routes>   
        </Router>
    </div>
  );
}

export default App;
