import React, { useState } from 'react';
import { React360Viewer } from 'react-360-product-viewer';
import icon3d from "../assets/icon/360-degrees.webp"


function ThreeSixty() {

  const [showIcon, setShowIcon] = useState(true);

  // function handleClickUp() {
  //   setShowIcon(true);
  // }  

  // function handleClickDown() {
  //   setShowIcon(false);
  // }  

  return (
    <>
        <React360Viewer
          imagesBaseUrl="https://www.careisgold.it/3d/100g/"
          imagesCount={51}
          imagesFiletype="webp"
          imageInitialIndex={5}
          mouseDragSpeed={20}
          autoplay={true}
          autoplaySpeed={8}
          width={600}
          height={600}
          reverse={true}
          shouldNotifyEvents={true}
          // notifyOnPointerDown={handleClickDown}
          // notifyOnPointerUp={handleClickUp}
          showRotationIconOnStartup={false}
        />
        {showIcon && (
          <div className="icon360 mx-auto">
            <img src={icon3d} alt="icona 360" className="img-fluid"/>
          </div>
        )}
    </>
  );
}

export default ThreeSixty;
