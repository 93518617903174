import React, { useEffect, useRef, useState } from "react";
import ContactForm from "../../components/ContactForm";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from '../../components/Footer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cofanetto from "../../assets/landing/landingOroPrestige/Schermata_2023-11-14.png";
import brochure from "../../assets/oroPrestige/oro-prestige.pdf";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { Autoplay, Navigation } from 'swiper/modules';
import axios from "axios";
import { Link } from "react-router-dom";
import ThreeSixty from "../../components/ThreeSixty";
import bodyImg4 from "../../assets/oroPrestige/oroprestige icon.webp";
import { Helmet } from "react-helmet";



function Careisgold() {

  const handleDownloadBrochure = () => {
    
    // Create a temporary link element
    const link = document.createElement('a');
    link.href = brochure;
    link.download = 'brochure.pdf'; // Set the desired file name
    
    // Append the link to the body and trigger the download
    document.body.appendChild(link);
    link.click();
    
    // Clean up
    document.body.removeChild(link);
  };


  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState(posts);
  const [uniqueCatPosts, setUniqueCatPosts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);


  const openModal = () => {
    document.body.style.overflow = 'hidden';
    setIsModalOpen(true);
  };

  const closeModal = () => {
    const errorModal = document.querySelector('#errorPage');
    const successModal = document.querySelector('#thankyouPage');

    if(errorModal){
      window.location.reload();
      return;
    }

    if(successModal){
      window.location.href = 'https://www.careisgold.it/'
      return;
    }

    document.body.style.overflow = 'auto'; 
    setIsModalOpen(false);
  };

  useEffect(() => {

    const handleOutsideClick = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        closeModal();
      }
    };
    
    if (true) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };

  }, []);


  const handleScroll = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  useEffect(() => {

    const fetchData = async () => {
      try {
        const res = await axios.post(`https://crm.careholding.it/ws/Call/?Cat=Blog&met=GetPostsRS&np=0`);
        setPosts(res.data);
        setFilteredPosts(res.data);
        
        const uniqueCatValues = [...new Set(res.data.map(post => post.IdCategory))];
        const postsByUniqueCat = {};

        uniqueCatValues.forEach(uniqueCatValue => {
            const filteredPosts = res.data.filter(
              post => post.IdCategory === uniqueCatValue && post?.Stato === 1 // Optional chaining for 'Stato'
            );
            postsByUniqueCat[uniqueCatValue] = filteredPosts;
          });

        setUniqueCatPosts(postsByUniqueCat);
  
  
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  
  }, []);


function arrowFV (){
    const startContent = document.querySelector("#startContent");
    const topOfElement = startContent.getBoundingClientRect().top + window.scrollY;

    window.scrollTo({
        top: topOfElement,
        behavior: "smooth",
    });
}

function arrowOP (){
  const startContent = document.querySelector("#secondArrow");
  const topOfElement = startContent.getBoundingClientRect().top + window.scrollY;

  window.scrollTo({
      top: topOfElement,
      behavior: "smooth",
  });
}

const containerStyle = {
  width: '100%', // Width of the container and iframe
  height: '80vh', // Desired height to crop to
  overflow: 'hidden',
  position: 'relative',
  // position: "absolute",
  top: "0",
  left: "0",
};

const iframeStyle = {
  position: 'absolute',
  top: '-200px', 
  left: '0',
  width: '2200px', 
  height: '1280px', 
};

const containerStyle1 = {
  position: 'relative',
  width: '100%',
  paddingBottom: '50%', 
  overflow: 'hidden',
};

const iframeStyle1 = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '113%',
};

const videoWrapperStyle1 = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
};
  
  return (
    <>
        <Helmet>
            <title>Careisgold SpA: Oro da Investimento 24k per Gioiellieri</title>
            <meta property="og:title" content="Careisgold SpA: Oro da Investimento 24k per Gioiellieri" />
            <meta property="og:description" content="Scopri Oro Prestige: lingotti 24k, vantaggi fiscali, formazione per gioiellieri. Eleva la tua professione!" />
            <meta property="og:url" content="https://www.careisgold.it/oro-prestige-lingotti-in-gioielleria" />
            <meta name="description" content="Scopri Oro Prestige: lingotti 24k, vantaggi fiscali, formazione per gioiellieri. Eleva la tua professione!" />
            <meta name="keywords" content="oro puro fisico da investimento per gioiellieri, lingotti oro 24k certificati per gioielleria, vantaggi fiscali oro fisico da investimento gioielleria, formazione personalizzata gioiellieri oro fisico da investimento" />
            <meta name="author" content="Careisgold S.p.A" />
        </Helmet>
        <div className="fullscreen-video-op">
        <div style={containerStyle1}>
          <div style={videoWrapperStyle1}>
            <iframe
              src="https://www.youtube.com/embed/18wurmsPOlQ?autoplay=1&loop=1&mute=1&controls=0&playlist=18wurmsPOlQ"
              title="YouTube Video"
              frameBorder="0"
              allowFullScreen
              autoPlay
              muted
              loop
              controls={false} // Hide the controls
              style={iframeStyle1}
            ></iframe>
          </div>
          <div className="overlay-video-header-op"></div>
        </div>
          <div className="overlay-content-op d-flex justify-content-center">
              <div className="text-center d-flex align-items-bottom" >
                  <button onClick={openModal} className="bg-yellow-landing-op text-dark px-5 py-3 rounded-pill mb-5 overlay-button-op" style={{"margin-top":"auto"}}>Scopri come elevare la tua professione</button>
              </div>
          </div>
          {/* <div className="overlay-video-op"></div> */}
          <FontAwesomeIcon icon="fa-solid fa-chevron-down" className="overlay-arrow-op display-1 arrow-landing-op arrow-icon-op mt-md-5 mt-5" onClick={arrowFV} />
        </div>
        <div className="container-fluid bg-light">
          <div className="container" id="startContent">
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col-xl-8 col-lg-8 col-12 text-center py-lg-5 py-0 px-md-4">
                  <p className="fw-semibold fs-2 mb-4 mt-0 fw-bold text-dark" data-aos="fade-zoom-in">Sei Titolare di una Gioielleria?
                  Desideri un nuovo prodotto esclusivo per i Tuoi Clienti?</p>
                </div>
            </div>
          </div>
        </div>
        <div className="container-fluid m-0 p-0 bg-landing-op">
          <div className="container-fluid bg-dark bg-opacity-50">
            <div className="row d-flex justify-content-center align-items-center py-5">
                <div className="col-xl-8 col-lg-8 col-12 text-center py-md-5 py-4 px-md-4">
                  <p className="fw-semibold display-3 mb-4 mt-4 fw-bold text-light" data-aos="fade-zoom-in">Oro Puro Fisico da investimento</p>
                  <p className="fs-3 mb-4 mt-4 text-light" data-aos="fade-zoom-in">L'acquisto che protegge il presente e garantisce il futuro</p>
                  <FontAwesomeIcon icon="fa-solid fa-chevron-down" className="display-1 title-landing-odi arrow-icon-op" onClick={arrowOP} />
                </div>
            </div>
          </div>
        </div>
        <div className="container-fluid py-lg-5 pt-5 bg-white" id="secondArrow">
          <div className="container pb-lg-5">
            <div className="row d-flex justify-content-center align-items-end">
              <div className="col-8 text-center">
                <p className="text-dark fs-2 fw-bold">Oro Prestige by Careisgold offre ai gioiellieri
                le migliori soluzioni sul mercato</p>
              </div>
              <div className="col-lg-6 col-10 text-center">
                <ThreeSixty/>
              </div>
              <div className="col-lg-5 col-10 text-lg-start text-center mt-md-5 mt-lg-0 pt-md-5 pt-lg-0 pb-lg-3">
                <p className="fs-3 fw-semibold pb-5">Purezza 24k a 999,9/1000<br/>Certificazione Good Delivery<br/>Lingotti in versione Kinebar®<br/>Caveau assicurato al 100%</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-landing-care">
        <div class="container">
            <div class="row d-flex justify-content-center align-items-center">
              <div className="col-md-8 col-12 text-center">
                <p className="fs-2 arrow-landing-op mt-5">OLTRE IL REGALO</p>
                <p className="text-light fs-4 fw-semibold"><em>Diventa un incaricato alla promozione nella tua gioielleria e punto di riferimento
                in Oro Puro Fisico da investimento</em></p>
              </div>
                <div class="col-xl-6 col-12">

                    <div class="container">
                        <div class="row d-flex align-items-center justify-content-center" data-aos-duration="2000" data-aos="fade-zoom-in">
                            <div class="col-3 col-md-1 text-center py-4">
                                <img src={bodyImg4} class="img-fluid mb-1" alt="icona"/>  
                            </div>
                            <div class="col-12 col-md-10">
                                <h3 class="text-light fw-semibold text-md-start text-center">
                                  Accesso a Prodotti Esclusivi e Clienti di Alto Profilo
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div class="container">
                        <div class="row d-flex align-items-center justify-content-center" data-aos-duration="2000" data-aos="fade-zoom-in">
                            <div class="col-3 col-md-1 text-center py-4">
                                <img src={bodyImg4} class="img-fluid mb-1" alt="icona"/>  
                            </div>
                            <div class="col-12 col-md-10">
                                <h3 class="text-light fw-semibold text-md-start text-center">Nessun investimento di capitale</h3>
                            </div>
                        </div>
                    </div>
                    
                    <div class="container">
                        <div class="row d-flex align-items-center justify-content-center" data-aos-duration="2000" data-aos="fade-zoom-in">
                            <div class="col-3 col-md-1 text-center py-4">
                                <img src={bodyImg4} class="img-fluid mb-1" alt="icona"/>  
                            </div>
                            <div class="col-12 col-md-10">
                                <h3 class="text-light fw-semibold text-md-start text-center">Nessun limite di guadagno</h3>
                            </div>
                        </div>
                    </div>

                    <div class="container">
                        <div class="row d-flex align-items-center justify-content-center" data-aos-duration="2000" data-aos="fade-zoom-in">
                            <div class="col-3 col-md-1 text-center py-4">
                                <img src={bodyImg4} class="img-fluid mb-1" alt="icona"/>  
                            </div>
                            <div class="col-12 col-md-10">
                                <h3 class="text-light fw-semibold text-md-start text-center">Vantaggi Fiscali e agevolazioni Previdenziali</h3>
                            </div>
                        </div>
                    </div>

                    <div class="container">
                        <div class="row d-flex align-items-center justify-content-center" data-aos-duration="2000" data-aos="fade-zoom-in">
                            <div class="col-3 col-md-1 text-center py-4">
                                <img src={bodyImg4} class="img-fluid mb-1" alt="icona"/>  
                            </div>
                            <div class="col-12 col-md-10">
                                <h3 class="text-light fw-semibold text-md-start text-center">Assistenza e Formazione Personalizzate</h3>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-xl-6 mt-xl-0 mt-5 col-12 col-md-10">
                  <img src={cofanetto} alt="lingotto di presentazione" className="img-fluid" />
                </div>
                <div className="col-12 text-center">
                  <button className="bg-yellow-landing-op text-dark px-5 py-3 rounded-pill mb-5 mt-5" id="downloadBrochure" onClick={handleDownloadBrochure}>Scarica la brochure</button>
                </div>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-light">
        <div className="row d-flex justify-content-center align-items-center bg-black">
                <div className="col-md-11 col-12 text-center">
                    <p className="text-default fw-semibold m-0 pb-0 pt-5 fs-1 position-relative" data-aos="fade-zoom-in" style={{"top":"2rem"}}>Hanno scritto di noi</p>
                </div>
                <div className="col-12">
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        centerInsufficientSlides={true}
                        breakpoints={{
                            640: {
                              slidesPerView: 2,
                              spaceBetween: -100,
                            },
                            768: {
                              slidesPerView: 3,
                              spaceBetween: 0,
                            },
                            1024: {
                              slidesPerView: 4,
                              spaceBetween: 0,
                            },
                            1200: {
                                slidesPerView: 5,
                                spaceBetween: -400,
                            },
                            1400: {
                                slidesPerView: 6,
                                spaceBetween: -400,
                            },
                          }}
                        autoplay={{
                            delay: 1000,
                            disableOnInteraction: false,
                        }} 
                        navigation={true}
                        modules={[ Autoplay , Navigation]}
                        className="mySwiper"
                        > 
                              
                        <ul className="stories">     
                        
                        {Object.values(uniqueCatPosts).map(postsWithSameCat => {
                            const categoryId = postsWithSameCat[0].IdCategory;
                            const postsWithSameCategory = posts.filter(post => post.IdCategory === categoryId);
                            const isActive = JSON.stringify(filteredPosts) === JSON.stringify(postsWithSameCategory);
                            return(
                            postsWithSameCat.length > 0 ? (
                            <React.Fragment key={postsWithSameCat[0].IdCategory}>
                                <SwiperSlide>
                                <li className={`p-0 story-item-default ${isActive ? 'story-item-active' : ''}`}>
                                    <Link to="/notizie/rassegna-stampa" onClick={handleScroll}> 
                                    <div className="image">
                                        <img src={postsWithSameCat[0].ImgLogo} alt="Logo Redazione" className="" style={{"width":"10rem", "height":"10rem"}}/>
                                    </div>
                                    </Link>
                                </li>

                            </SwiperSlide> 
                            </React.Fragment>
                            ) : null
                        )})}
                        </ul>
                    </Swiper> 
                </div>
            </div>
        </div>
        {/* <div className="iframe-container-op bg-danger"> */}
          <div style={containerStyle}>
            <iframe
                src="https://www.youtube.com/embed/18wurmsPOlQ?autoplay=1&loop=1&mute=1&controls=0&playlist=Ipsg3A8r32s"
                title="YouTube Video"
                frameBorder="0"
                allowFullScreen
                style={iframeStyle}
                allow="autoplay; encrypted-media;"
            ></iframe>
            <div className="overlay-video-op"></div>
          </div>
        {/* </div> */}
        {isModalOpen && (
        <div className="modal-overlay-custom">
          <div className="container-fluid fixed-top lastModal">
            <div className="row align-items">
              <div className="col text-end">
                <button class="border-0 bg-transparent text-light p-md-4 p-0 pt-1" onclick={closeModal}>X</button>       
              </div>
            </div>
          </div>
          <div className="modal-custom" ref={modalRef}>
            <div className="modal-content-custom">
              <ContactForm 
                  NomeLanding='Landing oro prestige lingotti in gioielleria'
                  Tipo='Gioielliere'
                  BaseInputs={[    
                      { name: 'nomeCognome', required: true},
                      { name: 'email', required: true},
                      { name: 'telefono', required: true},
                  ]}
                  City={[{
                      city: false, 
                      required: true,
                      cityName: 'Città',
                      placeholder: 'Città'
                  }]}
                  DropDown={[{
                      dropDown: false, 
                      title:"Interessato/a a:", 
                      defaultOption: "Le nostre soluzioni", 
                      disabled: false, 
                      option1: 'Collabora con noi',
                      option2: 'altro',
                      option3: '',
                      required: false
                  }]}
                  Vote={[{
                      vote: false, 
                      name: "Quanto ti è piaciuto l'evento?", 
                      message: true, 
                      placeholder: 'Raccontaci la tua esperienza',
                      required: false
                  }]}
                  Message={[{
                      message: true, 
                      required: false,
                      messageName: 'Messaggio',
                      placeholder: 'altro...'
                  }]}
                  ConsensoDati={[{
                      consensoDati: true, 
                      required: true,
                      idPrivacy: 1
                  }]}
                  ConsensoNewsletter={[{
                      consensoNewsletter: false, 
                      listId: 48,
                      required: false,
                      idPrivacy: 3
                  }]}
                  SubmitButton={[{
                      submitName:'Invia', 
                      submittedName:'Inviato',
                      submittingName:'Sto inviando...'
                  }]}
                  SuccessMessage={[{
                      successMessage:'La tua richiesta è stata inoltrata con successo!', 
                      successButton:'Visita il nostro sito!', 
                      url:'https://www.careisgold.it/oro-prestige'
                  }]}
                />     
            </div>
          </div>
        </div>
      )}

      <div className="container-fluid bg-black pb-5" style={{'padding-top':'8rem'}}>
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-12 col-lg-6 col-xl-5">
                <ContactForm 
                  NomeLanding='Landing oro prestige lingotti in gioielleria'
                  Tipo='Gioielliere'
                  BaseInputs={[    
                      { name: 'nomeCognome', required: true},
                      { name: 'email', required: true},
                      { name: 'telefono', required: true},
                  ]}
                  City={[{
                      city: false, 
                      required: true,
                      cityName: 'Città',
                      placeholder: 'Città'
                  }]}
                  DropDown={[{
                      dropDown: false, 
                      title:"Interessato/a a:", 
                      defaultOption: "Le nostre soluzioni", 
                      disabled: false, 
                      option1: 'Collabora con noi',
                      option2: 'altro',
                      option3: '',
                      required: false
                  }]}
                  Vote={[{
                      vote: false, 
                      name: "Quanto ti è piaciuto l'evento?", 
                      message: true, 
                      placeholder: 'Raccontaci la tua esperienza',
                      required: false
                  }]}
                  Message={[{
                      message: true, 
                      required: false,
                      messageName: 'Messaggio',
                      placeholder: 'altro...'
                  }]}
                  ConsensoDati={[{
                      consensoDati: true, 
                      required: true,
                      idPrivacy: 1
                  }]}
                  ConsensoNewsletter={[{
                      consensoNewsletter: false, 
                      listId: 48,
                      required: false,
                      idPrivacy: 3
                  }]}
                  SubmitButton={[{
                      submitName:'Invia', 
                      submittedName:'Inviato',
                      submittingName:'Sto inviando...'
                  }]}
                  SuccessMessage={[{
                      successMessage:'La tua richiesta è stata inoltrata con successo!', 
                      successButton:'Visita il nostro sito!', 
                      url:'https://www.careisgold.it/oro-prestige'
                  }]}
                />     
          </div>
          <div className="col-4 d-xl-block d-none">
            <p className="text-default fw-semibold fs-2">
              Distinguiti dalla concorrenza e amplia il tuo business con una proposta di valore unica. Contattaci oggi stesso!
            </p>
          </div>
        </div>
      </div>

      <Footer/>
    </>
  )
}

export default Careisgold
